import React from 'react';
import { transformationStorageKey } from '../constants';

export const useTransformation = () => {

	const [ transformation, setTransformation ] = React.useState(false);

	const hideTransformation = React.useCallback(() => {
		setTransformation(false);
		if ( window.sessionStorage ) {
			sessionStorage.setItem( transformationStorageKey, '1' );
		}
	},[]);

	React.useEffect(() => {
		if ( window.sessionStorage ) {
				const approved = sessionStorage.getItem( transformationStorageKey );

				if ( !approved ) {
					setTransformation(true);
				}
		}
	},[]);

	return {
		transformation,
		hideTransformation
	};
}