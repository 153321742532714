import React from 'react';
import history from '../history';
import {
	BrowserRouter as BRouter
	, Route, Routes
} from 'react-router-dom';
import { routeNames } from '../constants/uiRouteNames';
import FullPageLoader from './Helpers/FullPageLoader';
import NotFound from './NotFound';
import Forbidden from './Forbidden';
import RequireAuth from './Auth/RequireAuth';
import AppLayout from './AppLayout';
import { showForgotPasswordFormAction, showResetPasswordFormAction, showSignUpFormAction, showLoginFormAction, showEmailVerificationAction } from '../actions/appActions';
import Dashboard from './Pages/Dashboard/Dashboard';
import CatalogLayout from './Pages/Catalog/CatalogLayout';
import SearchSizeLayout from './Pages/Search/SearchSizeLayout';
import SearchLayout from './Pages/Search/SearchLayout';

import ErrorHandler from './Helpers/ErrorHandler';
// import WelcomeLayer from './WelcomeLayer';

// const NotificationsLayout = React.lazy(() => import("./Pages/Notifications/NotificationsLayout"));
const MessagesLayout = React.lazy(() => import("./Pages/Messages/MessagesLayout"));
const Profile = React.lazy(() => import("./Pages/Profile/Profile"));
const Product = React.lazy(() => import("./Pages/Product/Product"));
const FavoriteList = React.lazy(() => import("./Pages/Favorite/FavoriteList"));
const FavoriteProducts = React.lazy(() => import("./Pages/Favorite/FavoriteProducts"));
const FavoriteUsers = React.lazy(() => import("./Pages/Favorite/FavoriteUsers"));
const SettingsLayout = React.lazy(() => import("./Pages/Settings/SettingsLayout"));
const Settings = React.lazy(() => import("./Pages/Settings/Settings"));
const SettingsOrdersLayout = React.lazy(() => import("./Pages/Settings/SettingsOrdersLayout"));
const SettingsOrders = React.lazy(() => import("./Pages/Settings/SettingsOrders"));
const SettingsProducts = React.lazy(() => import("./Pages/Settings/SettingsProducts"));
const SettingsExtra = React.lazy(() => import("./Pages/Settings/SettingsExtra"));
const UploadProduct = React.lazy(() => import("./Pages/Product/UploadProduct"));
const Cart = React.lazy(() => import("./Pages/Cart/Cart"));
const PaymentResponse = React.lazy(() => import("./Pages/Payment/PaymentResponse"));
const FillSellerInfo = React.lazy(() => import("./Pages/Settings/FillSellerInfo"));
const TermsOfService = React.lazy(() => import("./TermsOfService"));
const GDPR = React.lazy(() => import("./GDPR"));
const OptOut = React.lazy(() => import("./Pages/OptOut"));
const Insurance = React.lazy(() => import("./XInsurance"));
const Photo = React.lazy(() => import("./XPhoto"));
const Grow = React.lazy(() => import("./XGrow"));
const Wrapping = React.lazy(() => import("./XWrapping"));
const WhatTheUzuzdIs = React.lazy(() => import("./WhatTheUzuzdIs"));
// const AlmostZeroDelivery = React.lazy(() => import("./AlmostZeroDelivery"));
const HalfPriceDelivery = React.lazy(() => import("./HalfPriceDelivery"));
const Transformation = React.lazy(() => import("./Transformation"));

const Suspense = ({children}) => (
	<React.Suspense fallback={<FullPageLoader />}>
		{children}
	</React.Suspense>
);

const Router = () => {
	return (
		<BRouter history={history}>
			<ErrorHandler>
				<Routes>
					<Route path={routeNames.root}>
						<Route
							path={routeNames.login}
							element={
								<AppLayout immediateAppLayerAction={[ showLoginFormAction() ]} ></AppLayout>
							}
						/>
						<Route
							path={routeNames.signup}
							element={
								<AppLayout immediateAppLayerAction={[ showSignUpFormAction() ]} ></AppLayout>
							}
						/>
						<Route
							path={routeNames.forgotPassword}
							element={
								<AppLayout immediateAppLayerAction={[ showForgotPasswordFormAction() ]} ></AppLayout>
							}
						/>
						<Route
							path={routeNames.resetPassword + `/:token`}
							element={
								<AppLayout immediateAppLayerAction={[ showResetPasswordFormAction() ]} ></AppLayout>
							}
						/>
						<Route
							path={routeNames.verifyEmail + `/:id/:hash`}
							element={
								<AppLayout immediateAppLayerAction={[ showEmailVerificationAction() ]} ></AppLayout>
							}
						/>
					</Route>
					<Route path={routeNames.root} element={<AppLayout />}>
						<Route index element={<Dashboard />}/>
						<Route path={routeNames.paymentResponse} element={
							<Suspense>
								<RequireAuth from={routeNames.paymentResponse}>
									<PaymentResponse />
								</RequireAuth>
							</Suspense>
						}></Route>
						<Route path={routeNames.profile} element={
							<Suspense>
								{/* <RequireAuth from={routeNames.profile}> */}
									<Profile />
								{/* </RequireAuth> */}
							</Suspense>
						}>
							<Route path=":userId" element={
								<Suspense>
									<RequireAuth from={`${routeNames.profile}`}><Profile /></RequireAuth>
								</Suspense>
							}/>
						</Route>
						<Route path={routeNames.products}>
							<Route path=":productId/szerkesztes" element={
								<Suspense>
									<RequireAuth from={`/${routeNames.products}/${routeNames.profile}`}><UploadProduct /></RequireAuth>
								</Suspense>
							}/>
							<Route path=":productId" element={
								<Suspense>
									<Product />
								</Suspense>
							}/>
						</Route>
						{/* <Route path={routeNames.notifications} element={
							<Suspense>
								<RequireAuth from={routeNames.notifications}><NotificationsLayout /></RequireAuth>
							</Suspense>
						}/> */}
						<Route path={routeNames.messages} element={
							<Suspense>
								<RequireAuth from={routeNames.messages}><MessagesLayout /></RequireAuth>
							</Suspense>
						}>
							<Route path={`:threadId`} element={<Suspense><MessagesLayout /></Suspense>}/>
						</Route>
						<Route path={routeNames.favorites} element={
							<Suspense>
								<RequireAuth from={routeNames.favorites}><FavoriteList /></RequireAuth>
							</Suspense>
						}>
							<Route path={`${routeNames.products}`} element={<Suspense><FavoriteProducts /></Suspense>}/>
							<Route path={`${routeNames.users}`} element={<Suspense><FavoriteUsers /></Suspense>}/>
						</Route>
						<Route path={routeNames.settings} element={
							<Suspense>
								<RequireAuth from={routeNames.settings}><SettingsLayout /></RequireAuth>
							</Suspense>
						}>
							<Route index element={<Settings />}/>
							<Route path={`${routeNames.orders}`} element={<Suspense><SettingsOrdersLayout direction={routeNames.in} /></Suspense>}>
								<Route index element={<Suspense><SettingsOrders role="customer" /></Suspense>}/>
							</Route>
							<Route path={`${routeNames.orders}/${routeNames.in}`} element={<Suspense><SettingsOrdersLayout direction={routeNames.in} /></Suspense>}>
								<Route index element={<Suspense><SettingsOrders role="seller" /></Suspense>}/>
								<Route path={`:orderId`} element={<Suspense><SettingsOrders role="seller" /></Suspense>}/>
							</Route>
							<Route path={`${routeNames.orders}/${routeNames.out}`} element={<Suspense><SettingsOrdersLayout direction={routeNames.out} /></Suspense>}>
								<Route index element={<Suspense><SettingsOrders role="customer" /></Suspense>}/>
								<Route path={`:orderId`} element={<Suspense><SettingsOrders role="customer" /></Suspense>}/>
							</Route>

							<Route path={`${routeNames.products}`} element={<Suspense><SettingsProducts /></Suspense>}/>
							<Route path={`${routeNames.extra}`} element={<Suspense><SettingsExtra /></Suspense>}/>
						</Route>
						
						<Route path={routeNames.uploadProduct} element={
							<Suspense>
								<RequireAuth from={routeNames.uploadProduct} onDissmissNavigateTo={routeNames.root}>
									<UploadProduct />
								</RequireAuth>
							</Suspense>
						}/>
						<Route path={routeNames.fillSellerInfo} element={
							<Suspense>
								<RequireAuth from={routeNames.fillSellerInfo} onDissmissNavigateTo={routeNames.root}>
									<FillSellerInfo />
								</RequireAuth>
							</Suspense>
						}/>
						{/* <Route path={routeNames.welcome} element={
							<WelcomeLayer onModalDismiss={()=>{}}/>
						}/> */}
						
						<Route path={routeNames.catalog}>
							<Route path="*" element={ <CatalogLayout /> } />
							<Route index element={ <CatalogLayout /> } />
						</Route>
						<Route path={routeNames.searchSize}>
							<Route path="*" element={ <SearchSizeLayout /> } />
							<Route index element={ <SearchSizeLayout /> } />
						</Route>
						<Route path={routeNames.search}>
							<Route path="*" element={ <SearchLayout /> } />
							<Route index element={ <SearchLayout /> } />
						</Route>
						<Route path={routeNames.cart}>
							<Route index element={
							<Suspense>
								<Cart />
							</Suspense>
							} />
						</Route>
						<Route path={`${routeNames.insurance}`} element={<Suspense><Insurance /></Suspense>} />
						<Route path={`${routeNames.photo}`} element={<Suspense><Photo /></Suspense>} />
						<Route path={`${routeNames.grow}`} element={<Suspense><Grow /></Suspense>} />
						<Route path={`${routeNames.wrapping}`} element={<Suspense><Wrapping /></Suspense>} />
						<Route path={`${routeNames.whatis}`}>
							<Route index element={<Suspense><WhatTheUzuzdIs as={routeNames.seller} /></Suspense>} />
							<Route path={`${routeNames.seller}`} element={<Suspense><WhatTheUzuzdIs as={routeNames.seller} /></Suspense>} />
							<Route path={`${routeNames.customer}`} element={<Suspense><WhatTheUzuzdIs as={routeNames.customer} /></Suspense>} />
						</Route>
						{/* <Route path={`${routeNames.almostZeroDelivery}`} element={<Suspense><AlmostZeroDelivery /></Suspense>} /> */}
						<Route path={`${routeNames.halfPriceDelivery}`} element={<Suspense><HalfPriceDelivery /></Suspense>} />
						<Route path={`${routeNames.optOut}`} element={<Suspense><OptOut /></Suspense>} />
						<Route path={`${routeNames.transformation}`} element={<Suspense><Transformation /></Suspense>} />
					</Route>
					<Route path={`${routeNames.tos}`} element={<Suspense><TermsOfService /></Suspense>} />
					<Route path={`${routeNames.gdpr}`} element={<Suspense><GDPR /></Suspense>} />
					<Route path={`${routeNames.notFound}`} element={<NotFound/>} />
					<Route path={`${routeNames.forbidden}`} element={<Forbidden/>} />
					<Route path="*" element={<NotFound/>} />
				</Routes>
			</ErrorHandler>
		</BRouter>
	);
}

export default Router;