export const routeNames = {
	root: '/',
	login: 'login',
	logout: 'logout',
	notifications: 'ertesitesek',
	messages: 'uzenetek',
	uploadProduct: 'hirdetesfeladas',
	favorites: 'kedvencek',
	profile: 'adatlap',
	signup: 'signup',
	forgotPassword: 'forgot-password',
	resetPassword: 'reset-password',
	verifyEmail: 'verify-email',
	settings: 'beallitasok',
	settingsProducts: 'beallitasok/termekek',
	orders: 'csomagok',
	settingsOrders: 'beallitasok/csomagok',
	products: 'termekek',
	product: 'termek',
	users: 'tagok',
	editProduct: 'termekek/:productId/szerkesztes',
	extra: 'extra',
	tos: 'hasznalati-szabalyok',
	gdpr: 'adatvedelem',
	insurance: 'biztonsag',
	photo: 'fotok',
	grow: 'novekedjunk',
	wrapping: 'csomagolas',
	whatis: 'mi-a-uzuzd',
	seller: 'elado',
	customer: 'vevo',
	notFound: 'not-found',
	forbidden: 'forbidden',
	favoriteProducts: 'kedvencek/termekek',
	favoriteUsers: 'kedvencek/tagok',
	search: 'kereses',
	catalog: 'katalogus',
	dashboard: 'nyito',
	cart: 'kosar',
	in: 'eladasok',
	out: 'vasarlasok',
	paymentResponse: 'payment-response',
	fillSellerInfo: 'eladoi-adatok-kitoltese',
	welcome: 'hello',
	searchSize: 'kereses/meret',
	optOut: 'leiratkozas',
	// almostZeroDelivery: 'szallitas-1ft',
	halfPriceDelivery: 'felaru-szallitas',
	transformation: 'atalakulas',
};