import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import MobileNavigation from './MobileNavigation';
import Container from "@mui/material/Container";
import AppLayers from './AppLayers';
import Footer from './Base/Footer';
import Landings from './Landings';
import CookieConsent from './CookieConsent';
import Transformation from './Transformation';
import { useTransformation } from '../hooks/useTransformation';

const AppLayout = ({immediateAppLayerAction}) =>
{
	const {transformation, hideTransformation} = useTransformation();

	if ( transformation ) {
		return <Transformation hide={hideTransformation} />;
	}

	return (
		<>
			<Header />
			<Container
				component="main"
				maxWidth="auto"
				sx={theme => ({
					[theme.breakpoints.up("xs")]: {p: 0},
					minHeight: 600,
					mb: 20
				})}
			>
				<Outlet/>
			</Container>
			<MobileNavigation />
			<Footer />
			<AppLayers
				immediateAction={immediateAppLayerAction}
			/>
			<Landings />
			<CookieConsent />
		</>
	);
}

export default AppLayout;